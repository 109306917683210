@import '../../styles/colors';
@import '../../styles/mixins';
.titleBorder{
  color: #e0e0e0;
    margin-left: -2%;
    margin-right: -2%;
    height: 1px;
    border: none;
    background-color: #e0e0e0;
    margin-bottom: 15px;
}
.breadcrumbs {
  @include flex-align-center;

  flex-wrap: wrap;
  width: 100%;
  font-size: 14px;
  margin: -9.5px -4.5px 0.5px;

  &--item {
    @include flex-align-center;

    a {
      text-decoration: none;
      color: #000000;
      font-weight: 600;
    }
    .slashIcon{
      margin-left: 4px;
      font-size: 17px;
    }
    .disabled {
      color: #000000;
      cursor: default;
      pointer-events: none;
      font-weight:400;
      margin-left: 5px;

      path {
        fill: $canceled-grey;
      }
    }

    svg {
      min-width: 6px;
      margin-left: 9px;

      path {
        fill: $color-brand-blue;
      }
    }
  }
}
