@import '../../../styles/colors';
@import '../../../styles/constants';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
.userProfile-border{
  color: #e0e0e0;
  height:1px;border:none;
  background-color:#e0e0e0;
  margin:0px;
}
.crossIcon{
  float: right;
  margin-top: -8%;
  margin-right: 2%;
  cursor: pointer;
}
.appNavItem{
  border-bottom: 1px solid #e0e0e0;
}

.userMenuIcon {
  &__menu {
    width: 320px;
  }
  &__user {
    margin-bottom: 12px;
    width: 100%;
    max-width: 100%;

    &__avatar {
      & > .appUserAvatar {
        width: 96px;
        height: 96px;
        min-width: unset;
        border-radius: 50%;
        margin-left:auto;
        margin-right:auto;
        margin-top:40px;
        background-color: #000000;

        & > span {
          font-size: $font-size-semi-normal;
          color: #ffffff;
        }
      }
    }

    &__info {
      grid-area: userInfo;
      min-height: 48px;
      color: $color-grey-25;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      margin-top: 10px;

      &__name {
        font-weight: 600;
        color: $black;
      }
    }
  }

  &__content {
    &__languageSelect {
      margin: 16px 16px 16px !important;
    }
  }

  &__footer {
    grid-area: footer;
    text-align: right;
    border-top: 1px solid $color-grey-85;
    padding-top: 15px;

    & > span {
      cursor: pointer;
      color: $color-brand-blue;
      font-weight: $font-weight-semi-bold;
      max-width: 100%;
    }
  }
}
