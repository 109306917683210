@import '../../../styles/colors';
@import '../../../styles/fonts';

.update-performance-hurdle-modal {
    .k-dialog {
        width: 100%;
    }
    .modalLayout-wrapper {
        padding: 16px 16px 24px 16px;
    }
    .performanceHurdle-border{
        color: #e0e0e0;
        margin-left:-2%;
        margin-right:-2%;
        margin-top:-2%;
        height:1px;border:none;
        background-color:#e0e0e0;
        margin-bottom:15px;
    }
    .perormanceHurdleID {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }

    .tableHeader {
        text-align: left;
        padding-left: 10px;
        padding-bottom: 1%;
        font-Weight: 600;
        font-size: 12px;
        line-height: 16px;
        max-width: 150px;
        overflow-wrap: break-word;
    }

    .add-sh-btn {
        width: 500px;
    }

    .tableColumn {
        padding-left: 10px;
    }

    .date_padding {
        margin-top: -9%;
        height: 32px;
        width: 150px;
        border: 1px solid #A7A8AA;
    }

    .input_box {
        padding-right: 0px;
    }

    .final_input .k-picker-wrap .k-dateinput .k-dateinput-wrap {
        background-color: #EBF5F9;
    }

    .datePicker .k-datepicker {
        width: 150px;
        height: 32px;
        border-radius: 0;
    }

    .dateText {
        color: $error;
        margin-top: 4px;
        font-size: 12px;
        margin-left: 10px
    }
    .validationText{
        color: $error;
        margin-top: 4px;
        font-size: 12px;
        margin-left: 10px;
        margin-bottom: 0px;
        width:520px;
    }
    
    .guidance_estimatedDate{
        color: $error;
        margin-top: 10px;
        font-size: $font-size-normal;
        margin-left: 10px;
        margin-bottom: 0px;
    }

    .disable_final_button {
        border: 1px solid #A7A8AA;
        color: #A7A8AA;
    }

    .datePicker .k-datepicker.final_input .k-select {
        background-color: #EBF5F9;
    }


    .final_input {
        background-color: #EBF5F9;
    }

    .update-cross-icon {
        cursor: pointer;
    }

    .subhurdle_input {
        height: 32px;
        width: 150px;
        border: 1px solid #A7A8AA;
    }

    .add-up-button {
        float: left;
        padding: 20px 9px 0 0;
        color: #007CB0;
        font-weight: 600;
        cursor: pointer;
        margin-top: 16px;

        div {
            display: inline;
        }

        svg {
            margin: 0 5px -2px 0;
        }
    }

    .disable-up-div {
        opacity: 0.4;
        pointer-events: none;
        color: #A7A8AA;
    }

    .finalResultButton {
        display: inline;
        position: absolute;
        margin-top: 29px;
    }
}