@import '../../styles/colors';
@import '../../styles/constants';
@import '../../styles/fonts';

.IconMenu {
  position: relative;
  font-size: $font-size-normal;
  line-height: $line-height-normal;

  &__trigger {
    cursor: pointer;
    min-width: 32px;
    display: flex;

  }

  &__menu {
    &__open {
      opacity: 1;
      visibility: visible;
    }

    position: absolute;
    display: flex;
    flex-direction: column;
    width: $sidebar-width-open;
    background: $white;
    z-index: 1100;
    padding: 16px;
    border: 1px solid $color-grey-85;
    box-shadow: 0 0 8px $color-rgba-black-8;
    border-radius: 2px;
    right: 0;
    top: calc(100% + 8px);
    transform: translateY(0);
    height: fit-content;
    opacity: 0;
    visibility: hidden;
    transition: $dropdown-transition;
  }
}
