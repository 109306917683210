@import '../../styles/colors';
label.k-label.k-text-error{h3.label{
  color:#d51923;
}
}
.label {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 8px;
  color: $label-color;

  &__required {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: $dark-red;
  }

  & > svg {
    margin-right: 5px;
    vertical-align: sub;
  }

  & > i,
  & > svg:last-child:not(:first-child),
  & > .IconTooltip {
    margin-left: 8px;
    vertical-align: sub;
  }
}
