@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/constants';

.VendorSwitch__Wrapper {
  @include flex-center;

  &__label {
    color: $black !important;
    display: block;
    transition: $dropdown-transition;
  }

  &__container {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;

    & > * {
      margin: 4px;
    }

    & > .k-switch {
      width: auto;
      height: auto;

      & > .k-switch-container {
        width: 32px;
        height: 16px;
        box-shadow: none !important;
      }
    }

    & > .k-switch-off {
      & > .k-switch-container {
        background-color: $color-select-border;
      }

      .k-switch-handle {
        left: 2px;
      }
    }

    & > .k-switch-on {
      & > .k-switch-container {
        background-color: $color-icon-green;
      }

      .k-switch-handle {
        left: calc(100% - 12px - 2px);
      }
    }

    .k-switch-handle {
      border: none;
      background-color: $white !important;
      width: 12px;
      height: 12px;
    }

    &__label {
      @include flex-align-center;
    }

    &-withLabel {
      margin: -4px;

      &--top {
        flex-direction: column;
        align-items: flex-start;
      }

      &--bottom {
        flex-direction: column-reverse;
        align-items: flex-start;
      }

      &--left {
        flex-direction: row;
        align-items: center;
      }

      &--right {
        flex-direction: row-reverse;
        align-items: center;
      }
    }
  }
}
