@import '../../../styles/colors';

.helpCenter {
  .IconTooltip {
    display: inline-flex;
  
    &__iconWrapper {
      display: flex;
  
      & > svg > path {
        transition: fill 0.2s ease;
        fill: #000000;
      }
  
      &:hover > svg > path {
        fill: #000000;
      }
    }
  }
  margin-right: 16px;
  &__menu {
    padding-top: 8px;
  }

  &__header {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 16px;
  }

  &__text {
    font-size: 12px;
    line-height: 16px;

    div {
      display: inline;

      a {
        text-decoration: none;
        font-weight: 600;
      }
    }
  }
}

span.helpCenter{
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}
