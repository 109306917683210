@import './styles/colors';
@import './styles/mixins';

// DatePicker, DropDownList
.k-list-container,
.k-calendar-container {
  z-index: 10002 !important;
}

.DeloitteDatePicker {
  .k-calendar-navigation {
    box-shadow: none;

    .k-calendar-navigation-highlight {
      border: none;
    }

    .k-reset span,
    .k-calendar-navigation-marker {
      color: $black;
      font-weight: normal;
      font-size: 14px;
    }
  }

  .k-calendar-view {
    width: max-content;

    .k-calendar-th {
      font-size: 14px;
      color: $color-grey-45;
      text-transform: capitalize;
    }

    .k-calendar-header {
      .k-calendar-title,
      .k-nav-today {
        height: 32px;
        font-weight: 600;
        font-size: 14px;
        white-space: nowrap;
        border-radius: 2px;
        padding: 4px 16px;

        @include flex-center;
      }

      .k-calendar-title {
        @include reset-button-styles($type: 'text');

        color: $black !important;
      }

      .k-nav-today {
        @include reset-button-styles($background: $color-primary-blue, $type: 'text');
      }
    }

    .k-calendar-content {
      .k-calendar-th {
        font-weight: 600;
      }

      .k-today {
        .k-link {
          color: $black;
          border: 1px solid $color-primary-blue;
          box-shadow: none;
        }
      }
    }

    &.k-calendar-yearview,
    &.k-calendar-decadeview,
    &.k-calendar-centuryview {
      .k-content .k-calendar-tbody .k-calendar-tr .k-calendar-td {
        span {
          width: 60px;
          height: 36px;
        }
      }

      .k-calendar-td.k-state-pending-focus {
        span {
          background-color: $color-primary-blue;
          color: $white;
          box-shadow: none;
        }
      }

      .k-calendar-td.k-state-pending-focus.k-today {
        span {
          background-color: $white;
          color: $black;
          box-shadow: none;
        }
      }
    }

    &.k-calendar-monthview {
      .k-content .k-today {
        span {
          border-radius: 50%;
          box-shadow: none;
        }

        &:hover {
          span {
            border-radius: 50%;
          }
        }

        .k-content .k-weekend {
          border-radius: 50%;
        }
      }

      .k-state-pending-focus.k-state-focused .k-link {
        border-radius: 50%;
      }

      .k-content .k-calendar-tbody .k-calendar-td {
        &:hover {
          span {
            background-color: $color-blue-01;
            border-radius: 50%;
          }
        }
      }

      .k-content .k-calendar-tbody .k-calendar-td.k-state-selected {
        span {
          background-color: $color-primary-blue;
          border-radius: 50%;
          color: $white;
          box-shadow: none;
        }

        &:hover {
          span {
            background-color: $color-blue-01;
            color: $black;
          }
        }

        &:active {
          span {
            background-color: $pressed-blue;
          }
        }
      }
    }
  }

  .k-calendar .k-month td .k-calendar .k-content .k-weekend,
  .k-calendar .k-content .k-today,
  .k-calendar .k-content td {
    &:hover {
      span.k-link {
        background-color: $color-blue-01;
        border-color: $color-blue-01;
        box-shadow: none;
        color: $black;
      }
    }

    &:active {
      span.k-link {
        background-color: #d6eaf2;
      }
    }
  }
}

.k-animation-container {
  // for tooltips
  z-index: 1100;

  .k-list-scroller .k-list .k-item.k-state-focused {
    box-shadow: none;
  }

  .k-list .k-item {
    height: 40px;

    &:hover {
      background: $color-blue-01;
    }

    &:hover.k-state-selected {
      background: $color-blue-01;
      color: $black;
    }

    &.k-state-selected {
      background: $color-blue-01;
      color: $black;
    }
  }

  .k-popup.k-child-animation-container.k-slide-down-enter.k-slide-down-enter-active .k-list-scroller {
    max-height: 256px !important;
    padding: 8px 0;
  }

  // used for dropDownList
  .k-list-optionlabel {
    display: none;
  }
}

.k-datepicker .k-dateinput-wrap {
  input {
    pointer-events: none;
    caret-color: transparent;
  }
}
