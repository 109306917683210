@import '../../styles/colors';
@import '../../styles/mixins';

.appUserAvatar {
  @include flex-center;
  width: 35px;
  height: 35px;
  background: #ffffff;
  border: 1px solid $color-grey-25;
  cursor: pointer;
  border-radius: 26px;

  &.disabled {
    cursor: default;
  }

  & > img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  & > span {
    font-weight: 600;
    font-size: 12px;
    color: #000000;
    text-transform: uppercase;
  }

  &--lg {
    width: 260px;
    min-width: 260px;
    height: 260px;

    & > span {
      font-weight: 600;
      font-size: 60px;
    }
  }
}
