// Branding
$color-brand-green: #86bd24;
$color-brand-highlight: #86bd24;
$color-brand-blue: #007db3;
$color-brand-red: #da291c;
$color-brand-orange: #ffcd00;
$color-brand-navigation-link: #fff;
$color-brand-banner-nav-links: #7186aa;
$color-brand-subtle-text: #888;
$color-brand-grey: #2f2f2f;
$color-brand-primary: #86bd24; // brand-primary-green
$color-primary-background: #fff;
$color-select-option: #5ebdec;
$color-select-border-hover: #007cb0;
$color-select-border: #a7a8aa;

// Icons
$color-brand-soft-blue: #0194cc;
$color-brand-softer-blue: #99d9f2;
$color-icon-blue: #00a3e0;
$color-icon-navy: #002776;
$color-icon-turquoise: #00abab;
$color-icon-green: #43b02a;
$color-icon-cyan: #004f59;
$color-asterisk-icon: #ce352c;

//
$color-turquoise: #00abab;

// Grey
$color-grey-100: #888;
$color-grey-90: #f5f5f5;
$color-grey-85: #ebebeb;
$color-grey-80: #eee;
$color-grey-75: #e0e0e0;
$color-grey-70: #e5e5e5;
$color-grey-65: #bdbdbd;
$color-grey-60: #bbb;
$color-grey-55: #a2a2a2;
$color-grey-57: #999;
$color-grey-55: #75787b;
$color-grey-50: #8a939d;
$color-grey-45: #63666a; // ui-grey-04
$color-grey-40: #6d6d6d; // ui-grey-04
$color-grey-30: #555;
$color-grey-25: #53565a;
$color-grey-20: #4a4a4a;
$color-grey-10: #222;
$color-grey-07: #202023;
$color-grey-05: #1f1f1f; // ui-grey-08
$color-grey-03: #f0f0f0;
$color-grey-02: #d6d6d6;
$color-grey-01: #bbbcbc;
$ui-grey-07: #2f2f2f;

// RGBa
$color-rgba-white-16: rgba(255, 255, 255, 0.16);
$color-rgba-white-25: rgba(255, 255, 255, 0.25);
$color-rgba-white-50: rgba(255, 255, 255, 0.5);
$color-rgba-black-90: rgba(0, 0, 0, 0.9);
$color-rgba-black-70: rgba(0, 0, 0, 0.7);
$color-rgba-black-50: rgba(0, 0, 0, 0.5);
$color-rgba-black-40: rgba(0, 0, 0, 0.4);
$color-rgba-black-30: rgba(0, 0, 0, 0.3);
$color-rgba-black-26: rgba(0, 0, 0, 0.2);
$color-rgba-black-24: rgba(0, 0, 0, 0.24);
$color-rgba-black-20: rgba(0, 0, 0, 0.2);
$color-rgba-black-12: rgba(0, 0, 0, 0.12);
$color-rgba-black-10: rgba(0, 0, 0, 0.1);
$color-rgba-black-01:rgba(0, 0, 0, 1);
$color-rgba-black-8: rgba(0, 0, 0, 0.08);
$color-rgba-black-4: rgba(0, 0, 0, 0.04);
$color-green: #86bd24; // support-green-01
$color-green-01: #7eb121; // support-green-01
$color-green-02: #c6d313; // support-green-02
$color-green-03: #2fa836; // support-green-03
$color-green-04: #17431f; // support-green-04
$color-green-05: #86bc25;
$color-green-06: #046a38;
$color-green-highlight: #e7f2d2; // support-green-tint-01
$color-green-highlight-01: $color-green-highlight; // support-green-tint-01
$color-green-highlight-02: #daebbd; // support-green-tint-02
$color-green-highlight-03: #cee4a7; // support-green-tint-03
$color-blue: #5abaea; // support-blue-01
$color-blue-01: #ebf5f9; // support-blue-01
$color-blue-02: #009bd7; // support-blue-02
$color-blue-03: #007299; // support-blue-03
$color-blue-highlight: #eef8fc; // support-blue-tint-01
$color-blue-highlight-01: $color-blue-highlight; // support-blue-tint-01
$color-blue-highlight-02: #e6f0f4; // support-blue-tint-02
$color-blue-highlight-03: #def1fb; // support-blue-tint-03
$color-orange: #f6a623; // support-orange-01
$color-orange-highlight: #fdedd3; // support-orange-tint-01
$color-orange-highlight-01: $color-orange-highlight; // support-orange-tint-01
$color-orange-highlight-02: #fce4bc; // support-orange-tint-02
$color-orange-highlight-03: #fbdba7; // support-orange-tint-02
$color-red: #e76c5e; // support-red-01
$color-red-highlight: #fae2df; // support-red-tint-01
$color-red-highlight-01: $color-red-highlight; // support-red-tint-01
$color-red-highlight-02: #f7d2ce; // support-red-tint-02
$color-red-highlight-03: #f5c4be; // support-red-tint-03

// Primary
$green: #86bd24;
$active-green: #009a44;
$orange: #ed8b21;
$blue: $color-brand-green;
$grey: #555;
$light-darken-grey: #e9e9e9;
$light-grey: #f6f6f6;
$black: #000;
$white: #fff;
$red: #de3226;
$color-ui-white: #fff;
$color-ui-black: #000;
$comment: #97999b;
$canceled-grey: $color-select-border;
$red: #da291d;
$yellow: #ffcd2e;
$color-primary-blue: #007cb0;
$color-primary-black-24: #c2c2c2;

// Secondary
$bright-blue: $color-brand-highlight;
$mountain-dew: #c9dd03;
$pleasant-green: #5dbd00;
$dark-green: #008b1e;
$poo-grey: #5e514d;
$bed-sheet-grey: #807370;
$luxury-grey: #404040;
$cool-grey: #8a939d;
$toddler-blue: #72c7e7;
$visited-link: #00143b;
$really-light-grey: #e8e8e8;
$warm-grey: #b5b5b5;
$dark-blue: #002776;

// Tertiary:
$almost-white: #f7f7f7;
$neutral1: #dfdddb;
$neutral2: #e2dfda;
$neutral3: #dad0ca;
$neutral4: #f3f1ee;
$neutral5: #f9f8f7;
$neutral6: #f3f1ee;

// Form validation
$success: #067a3d;
$success-lite: #dff0d8;
$error: $color-brand-red;
$error-lite: #f2dede;
$info: #8a6d3b;
$info-lite: #fcf8e2;
$background-color-error: #fceeed;

// Forms
$input-text: #212121;
$dark-red: $color-brand-red;
$cool-gray-6: $color-select-border;
$disabled-bg: #f5f5f5;
$label-color:#757575;

// Table
$table-row-bg-hover: #fbfbfb;

// Apps
$color-analyser: $green;
$color-dashboard: $blue;
$color-ledger: $really-light-grey;
$color-myob: #8745b5;
$color-quickbooks: #6bc111;
$color-receipt: #f2bf3a;
$color-transact: $color-brand-highlight;
$color-xero: #92e0ea;
$color-dais: $blue;
$color-dpcdais: $blue;
$color-etime: $color-grey-50;
$color-aps: $really-light-grey;
$color-yammer: #337989;
$color-trim: #0096d6;
$color-upload:rgba(33, 33, 33, 1);

// RGBa Greys
$color-rgba-grey-70: $color-rgba-black-70;
$color-rgba-grey-50: $color-rgba-black-50;
$color-rgba-grey-40: $color-rgba-black-40;
$color-rgba-grey-30: $color-rgba-black-30;
$color-rgba-grey-20: $color-rgba-black-20;
$color-rgba-grey-10: $color-rgba-black-10;

// Typography
$color-text-primary: #555; // text-on-light-primary
$color-text-secondary: #888; // text-on-light-secondary
$color-text-disabled: #ccc; // text-on-light-disabled
$color-text-alternate-primary: $white; // text-on-dark-primary
$color-text-alternate-secondary: #999; // text-on-dark-secondary
$color-text-alternate-disabled: #666; // text-on-dark-disabled

// Controls
$color-text-link: #86bd24;
$color-text-link-theme-green-disabled: $color-rgba-white-50;
$color-disabled: $color-rgba-white-50;

// Components
$color-button-primary: #86bd24;
$color-button-primary-hover: rgb(123, 171, 34);
$color-button-primary-active: #7eb121;
$color-button-secondary-hover: #f0f7e4;
$color-button-secondary-active: #daebbd;
$color-button-flat-hover: #f0f7e4;
$color-button-flat-active: #daebbd;

// Buttons
$button-disabled: #f0f0f0;
$button-hover: #7eb121;
$button-flat-disabled: $color-rgba-white-50;
$button-flat-disabled-grey: #666;
$button-blue: $color-brand-blue;
$button-background-red: $color-brand-red;
$pressed-blue: #d6eaf2;

// dropdown
$dropdown-background: $white;
$dropdown-border: #d0d0ce;
$dropdown-border-open: $color-brand-blue;
$dropdown-text-color: $black;
$dropdown-item-selected-color: $black;
$dropdown-icon-color: $color-brand-blue;
$dropdown-placeholder-color: $color-grey-55;
$dropdown-list-background: $white;
$dropdown-list-border: $color-grey-85;
$dropdown-list-box-shadow: $color-rgba-black-4;
$dropdown-li-color: $black;
$dropdown-li-hover: #ebf8fd;
$dropdown-li-active: #f5fcfe;
$z-index-dropdown-icon: 0;

// checkbox
$checkbox-border-color-default: #50565b;
$checkbox-label-color-default: #50565b;

// Single Select dropdown
$border-radius: 0;
$primary: #ebf5f9;
$secondary: #f6f6f6;
$info: #3e80ed;
$success: #5ec232;
$warning: #fdce3e;
$error: #d51923;
$body-text: #424242;
$body-bg: #fff;
$headings-text: #272727;
$subtle-text: #666;
$disabled-text: #8f8f8f;
$component-text: #424242;
$component-bg: #fff;
$base-text: #424242;
$base-bg: #fafafa;
$hovered-text: #424242;
$hovered-bg: #ececec;
$selected-text: #000;
$selected-bg: #ebf5f9;
$button-text: #424242;
$button-bg: #f5f5f5;
$link-text: #ff6358;
$link-hover-text: #d6534a;
$series-a: #ebf5f9;
$series-b: #ffd246;
$series-c: #78d237;
$series-d: #28b4c8;
$series-e: #2d73f5;
$series-f: #aa46be;
$toaster-error-background-color: rgba(249, 221, 218, 0.96);
$toaster-warning-background-color: rgba(252, 236, 214, 0.96);
$toaster-success-background-color: rgba(220, 236, 216, 0.96);
$new-file-color: #26890d;
