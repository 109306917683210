
.performance-hurdle-modal {
    .modalLayoutHeader{
        margin-bottom:15px;
    }
    .modalLayout-wrapper {
        padding: 12px 16px 12px 16px;
    }
    .performanceHurdle-border{
        color: #e0e0e0;
        margin-left:-2%;
        margin-right:-2%;
        height:1px;border:none;
        background-color:#e0e0e0;
        margin-bottom:15px;
    }
    .performanceHurdle-border-bottom{
        color: #e0e0e0;
        margin-left:-2%;
        margin-right:-2%;
        margin-top: -47px;
        height:1px;border:none;
        background-color:#e0e0e0;
    }
    .tooltip {
        position: relative;
        display: inline-block;
      }
      .tooltip .tooltiptext {
        visibility: hidden;
        width: 120px;
        background-color: black;
        color: #fff;
        text-align: center;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        bottom: 150%;
        left: 50%;
        margin-left: -60px;
      }
      .labelText{
        font-weight: 600;
        color:#757575;
        font-size: 12px;
        margin-top: -4px;
        display:block;
      }
      .tooltip .tooltiptext::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent;
      }
      
      .tooltip:hover .tooltiptext {
        visibility: visible;
      }
    .k-dialog {
        width: 800px;
    }
    .input_box{
        margin-top: 7px;
    }
    .inputField {
        width: 40%;
    }
    .subhurdles-group {
        margin-bottom: 100px;
    }
    .add-sh-btn {
        float: left;
        margin-top: -35px !important;;
    }
    .btn-group {
        display: flex;
        justify-content: flex-end;
        margin: 10px -8px 0;
    }
    .performanceHurdle-field {
        width: 100%;

    }
    .modalLayout__validation-message {
        left: 25px;
        bottom: 63px;
        position: absolute;
    }
    .VendorInput__Wrapper__label {
        font-weight: 600;
        color: #757575 !important;
        font-size: 12px;
    }
    .k-form-error   {
        font-style: normal;
    } 
    .sub-hurdle-item {
        .sub-hurdle-basic-info {
            display: inline-block;
            width: 100%;
            padding-top: 20px;
            .left-section {
                width: 48%;
                float: left;
            }
            .right-section {
                width: 48%;
                float: right;
                .IconTooltip {
                    margin: 0 5px !important;
                }
            }
              .labelTextlabelInvalidcolor{
                font-weight: 600;
                font-size: 12px;
                margin-top: -4px;
                display:block;
                color:#d51923;
              }
            .inputField:first-child {
                float: left;
            }
            .inputField:nth-child(2) {
                float: right;
            }
            
        }
        .add-vc-button {
            float: right;
            padding: 20px 58px 0 0;
            color: #007CB0;
            font-weight: 600;
            cursor: pointer;
            div {
                display: inline;
            }
            svg {
                margin:0 5px -2px 0;
            }
        }
        .disable-div {
            opacity: 0.4;
            pointer-events: none;
            background-color: #f0f0f0;
        }
        
        .vesting-condition-item {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            label.k-label.k-text-error.VendorInput__Wrapper__label{
                color:#d51923 !important;
              }
            .vc-item {
                width: 20%;
            }
            .vesting-cross-icon {
                margin-top: 40px;
                cursor: pointer !important;
            }
            .k-textbox:disabled {
                background-color: #f0f0f0;
            }         
            
        }
        .vc-header-section {
            display: inline-block;
            width: 100%;
            padding: 20px 0 0;
            .vc-header-title {
                font-weight: 600;
                font-size: 16px;
                float: left;
                color:#000;
            }
            .vc-radio-options {
                float: right;
                .k-radio {
                    border-color: #a7a8aa;
                    box-shadow: none !important;
                }
                .k-radio:checked, .k-radio.k-checked {                
                    color: #007cb0;
                    border-color: #007cb0;
                    background-color: #ffffff;
                }
            }
        }
    }
    .sub-hurdle-item:not(:first-child) {
        border-top: 1px solid #D0D0CE;
        margin-top: 65px;
    }
    
}