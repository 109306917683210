@import '../../styles/colors';
@import '../../styles/mixins';

.FormRadioGroup {
  .k-radio {
    border-color: $color-select-border;
    box-shadow: none !important;

    &:checked,
    &:checked:focus {
      border-color: $color-select-border-hover;
      background-color: $color-select-border-hover;
    }

    &::before {
      width: 6px;
      height: 6px;
    }

    &-label {
      font-size: 14px;
      color: $black;
    }

    & + .k-radio-label,
    .k-radio-label + &,
    .k-label + & {
      margin-left: 8px;
    }
  }
}
