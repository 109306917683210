@import '../../../styles/colors';
@import '../../../styles/mixins';

.NotificationList {
  width: 100%;
  background: $white;
  overflow-y: auto;

  @include custom-scrollbar;
}
