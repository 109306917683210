@import '../../../styles/colors';

.UserSettingsModal {
 .modalLayout-wrapper {
    padding: 12px 16px 12px 16px;
}
.userSettingLocale{
  background-color: #F0F0F0;
}
.modalLayoutHeader {
  margin-bottom: 10px;
}
  .FormRadioGroup {
    color: $black;
  }
  .UserSettingModal-border{
    color: #e0e0e0;
    margin-left: -3%;
    margin-right: -3%;
    height:1px;border:none;
    background-color:#e0e0e0;
    margin-bottom:15px;
}

}
