@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/constants';

$field-border: 1px solid $cool-gray-6;
$field-border-focus: 1px solid $color-brand-blue;
$field-border-error: 1px solid $error;
$icon-width: $form-field-height;
$clear-icon-width: calc(#{$icon-width} / 2);
label.k-label.k-text-error.VendorInput__Wrapper__label{
  color:red !important;
}
.VendorInput__Wrapper {
  $base: &;

  @include flex-justify-center;

  flex-direction: column;
  width: 100%;
  height: 36px;
  position: relative;

  &__label {
    color: $black !important;
    margin: 0 8px 8px 0;
    display: block;
    transition: $dropdown-transition;

    .required {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: $dark-red;
    }
    .k-text-error{
      color:red;
    }
  }

  &__error__text {
    transition: $dropdown-transition;
    font-size: 12px;
    line-height: 16px;
    color: $error;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 100%;
    left: 0;
    font-style: normal;
    font-weight: normal;
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  input {
    width: 100%;
    height: 100%;
    border: $field-border;
    border-radius: 0;
    transition: $dropdown-transition;
    outline: none !important;
    box-shadow: none !important;
    text-overflow: ellipsis;
    padding: 4px 8px;
    &:not(.k-state-invalid):hover,
    &:not(.k-state-invalid):focus,
    &:not(.k-state-invalid):hover ~ #{$base}__prepend__icon,
    &:not(.k-state-invalid):focus ~ #{$base}__prepend__icon,
    &:not(.k-state-invalid):hover ~ #{$base}__append__icon,
    &:not(.k-state-invalid):focus ~ #{$base}__append__icon {
      outline: none;
      box-shadow: none;
      border: $field-border-focus;

      svg {
        path {
          fill: $color-brand-blue;
        }
      }
    }

    &.k-state-invalid {
      border: $field-border-error;
      background-color: $background-color-error;
    }

    &.k-state-invalid ~ #{$base}__error__text {
      visibility: visible;
      opacity: 1;
    }

    &.k-state-invalid ~ #{$base}__prepend__icon,
    &.k-state-invalid ~ #{$base}__append__icon {
      svg {
        path {
          fill: $cool-gray-6;
        }
      }
    }

    &::placeholder {
      color: $cool-gray-6;
      font-weight: $font-weight-normal;
      font-size: $font-size-normal;
      line-height: $line-height-normal;
    }

    &::selection {
      background: $color-brand-blue;
      color: $white;
    }

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
      appearance: textfield;
    }
  }

  &__prepend,
  &__append,
  &__clear {
    &__icon {
      @include flex-center;

      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: $icon-width;
      min-width: $icon-width;
      height: $icon-width;
      transition: $dropdown-transition;
      border: none !important;

      & > svg {
        path {
          transition: $dropdown-transition;
        }
      }
    }
  }

  &__prepend {
    &__icon {
      border-right: none !important;
      left: 0;
    }

    input {
      padding-left: $icon-width;
    }
  }

  &__clear,
  &__append {
    &__icon {
      right: 3px;
    }
  }

  &__clear__icon {
    display: none;
    cursor: pointer;
    width: $icon-width;
    min-width: $icon-width;
    height: $icon-width;

    & > svg {
      path {
        fill: $black;
      }
    }
  }

  &__label:hover ~ &__clear > input:not(:placeholder-shown),
  &__clear:hover > input:not(:placeholder-shown),
  &__clear > input:focus:not(:placeholder-shown) {
    padding-right: $icon-width;
    & ~ #{$base}__clear__icon {
      display: flex;
    }
  }

  &__append {
    #{$base}__clear__icon {
      width: $clear-icon-width;
      min-width: $clear-icon-width;
    }

    &#{$base}__clear {
      #{$base}__clear__icon {
        right: $icon-width;
      }
      #{$base}__label:hover ~ & > input:not(:placeholder-shown),
      &:hover > input:not(:placeholder-shown),
      & > input:focus:not(:placeholder-shown) {
        padding-right: calc(#{$icon-width} + #{$clear-icon-width});
      }
    }

    input {
      padding-right: $icon-width;
    }
  }

  &__error__text.maxLength {
    visibility: visible;
    opacity: 1;

    .maxLengthText {
      color: $black;
    }

    .maxLengthError {
      color: $error;
    }
  }
}
