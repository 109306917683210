@import './fonts';
@import './colors';
@import './constants';

@mixin set-font(
  $font-family: $font-family-primary,
  $font-size: $font-size-normal,
  $font-weight: $font-weight-normal,
  $font-style: $font-style-normal,
  $line-height: $line-height-normal
) {
  font-family: $font-family;
  font-size: $font-size;
  font-weight: $font-weight;
  font-style: $font-style;
  line-height: $line-height;
}

@mixin hide-scrollbar {
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
}

@mixin custom-scrollbar {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    border: none;
    border-radius: 4px;
    background: $color-grey-90;
    box-shadow: 0 -1px 0 $color-rgba-black-8, 0 1px 0 $color-rgba-black-8;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $dropdown-border;
    border: none;
    border-radius: 4px;
  }
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-justify-center {
  display: flex;
  justify-content: center;
}

@mixin flex-align-center {
  display: flex;
  align-items: center;
}

@mixin sidebar-main-settings(
  $background: '',
  $navItemActiveBackground: '',
  $navItemActiveIconColor: '',
  $navItemIconColor: '',
  $navItemTextColor: '',
  $navbarItem: '.appNavItem'
) {
  $base: &;

  @include hide-scrollbar;

  top: $header-height;
  height: $sidebar-height;
  width: $sidebar-width-open;
  min-width: $sidebar-width-open;
  max-width: $sidebar-width-open;
  transition: $sidebar-transition;
  position: fixed;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  @if $background != '' {
    background: $background;
  }
  #{$navbarItem} {
    @if $navItemTextColor != '' {
      color: $navItemTextColor;
    }

    @if $navItemIconColor != '' {
      #{$navbarItem}--iconStartWrapper {
        path {
          fill: $navItemIconColor;
        }
      }
    }

    @if $navItemActiveBackground != '' {
      &.active,
      &:hover {
        background: $navItemActiveBackground;
      }
    }

    &.active {
      font-weight: 600;

      @if $navItemActiveIconColor != '' {
        #{$navbarItem}--iconStartWrapper {
          path {
            fill: $navItemActiveIconColor;
          }
        }
      }
    }
  }

  &--divider {
    height: 1px;
    transition: $sidebar-transition;
  }
}

@mixin sidebar-item-width {
  min-width: calc(#{$sidebar-width-open} - #{$sidebar-padding-x} - #{$sidebar-padding-x});
  max-width: 100%;
}

@mixin button-border-and-background($color) {
  background: $color;
  border: 1px solid $color;
}

@mixin button-border-and-background-important($color) {
  background: $color !important;
  border: 1px solid $color !important;
}

@mixin button-border-and-color($color) {
  color: $color;
  border: 1px solid $color;
}

@mixin button-border-and-color-important($color) {
  color: $color !important;
  border: 1px solid $color !important;
}

@mixin reset-button-styles($background: transparent, $type: '') {
  @if $type != 'outlined' {
    border: 1px solid transparent !important;
  }

  @if $background == transparent {
    border: 1px solid transparent !important;
  }

  cursor: pointer;
  transition: $button-transition;

  &,
  &:active,
  &:hover {
    @if $type == 'text' {
      color: $background;

      path {
        fill: $background;
      }

      @include button-border-and-background(transparent);
    } @else if $type == 'outlined' {
      @include button-border-and-color($background);

      path {
        fill: $background;
      }

      background: transparent;
    } @else {
      color: $white;

      path {
        fill: $white;
      }

      .overlay > .sk-circle > div::before {
        background-color: $white;
      }

      @include button-border-and-background($background);
    }

    outline: none;
  }

  @if $background == transparent {
    &:hover {
      @include button-border-and-background($color-rgba-white-16);
    }

    &:active {
      @include button-border-and-background(darken($color: $color-rgba-white-16, $amount: 30%));
    }
  } @else {
    @if $type == 'text' {
      &:hover {
        color: darken($color: $background, $amount: 5%);

        path {
          fill: darken($color: $background, $amount: 5%);
        }

        @include button-border-and-background(transparentize($background, 0.9));
      }

      &:active {
        color: darken($color: $background, $amount: 15%);

        path {
          fill: darken($color: $background, $amount: 15%);
        }

        @include button-border-and-background(transparentize($background, 0.8));
      }
    } @else if $type == 'outlined' {
      &:hover {
        @include button-border-and-color(darken($color: $background, $amount: 5%));

        path {
          fill: darken($color: $background, $amount: 5%);
        }

        background: transparentize($background, 0.9);
      }

      &:active {
        @include button-border-and-color(darken($color: $background, $amount: 15%));

        path {
          fill: darken($color: $background, $amount: 15%);
        }

        background: transparentize($background, 0.8);
      }
    } @else {
      &:hover {
        @include button-border-and-background(darken($color: $background, $amount: 5%));
      }

      &:active {
        @include button-border-and-background(darken($color: $background, $amount: 15%));
      }
    }
  }

  &:disabled {
    $disabled-color: $color-select-border;

    cursor: default !important;

    @if $background == transparent {
      @include button-border-and-background-important($background);

      color: $disabled-color !important;

      path {
        fill: $disabled-color !important;
      }
    } @else {
      @if $type == 'text' {
        @include button-border-and-background-important(transparent);

        color: $disabled-color !important;

        path {
          fill: $disabled-color !important;
        }
      } @else if $type == 'outlined' {
        @include button-border-and-color-important($disabled-color);

        background: transparent !important;

        path {
          fill: $disabled-color !important;
        }
      } @else {
        @include button-border-and-background-important($disabled-color);
      }
    }
  }
}
