@import './colors';

$header-height: 48px;
$sidebar-height: calc(100vh - #{$header-height});
$sidebar-width-open: 260px;
$sidebar-width-closed: 72px;
$sidebar-padding-x: 8px;
$sidebar-transition: all 0.5s ease;
$sidebar-content-transition: all 0.3s ease-out;
$button-transition: $sidebar-transition;
$dropdown-transition: all 0.2s ease;
$form-field-height: 32px;
$button-height: $form-field-height;
$table-border: 1px solid $color-grey-85;
