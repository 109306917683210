@import '../../../styles/colors';
@import '../../../styles/constants';

.NotificationPlaceholder {
  width: 100%;
  display: flex;
  flex: 2;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $black;

  & > svg {
    zoom: calc(120 / 28);

    path {
      fill: $color-rgba-black-24;
    }
  }

  & > span {
    margin-top: 38.5px;
  }
}
