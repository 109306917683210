@import '../../styles/colors';
@import '../../styles/constants';
@import '../../styles/mixins';

.appNavItem {
  $base: &;

  @include flex-align-center;

  flex-wrap: nowrap;
  padding: 8px 16px;
  width: 100%;
  height: 40px;
  border-radius: 2px;
  background: transparent;
  transition: $sidebar-content-transition, font-weight 1ms;
  font-size: 14px;
  line-height: 24px;
  text-decoration: none;
  color: $black;

  &--iconStartWrapper {
    @include flex-center;

    width: 24px;
    min-width: 24px;
    height: 24px;

    &.blue {
      path {
        fill: $color-icon-blue;
      }
    }

    &.cyan {
      path {
        fill: $color-icon-turquoise;
      }
    }

    &.green {
      path {
        fill: $color-icon-green;
      }
    }

    &.grey {
      path {
        fill: $color-grey-45;
      }
    }

    &.white {
      path {
        fill: $white;
      }
    }

    &.darkGreen {
      path {
        fill: $color-icon-cyan;
      }
    }

    path {
      transition: $sidebar-content-transition;
    }
  }

  & > span {
    flex: 1;
    overflow: hidden;
    min-width: 0;
    opacity: 1;
    transition: opacity 0.3s ease;
    white-space: nowrap;
    margin-left: 8px;
  }
}
