@import '../../styles/colors';
@import '../../styles/constants';
@import '../../styles/screens';
@import '../../styles/mixins';

$min-width: 176px;

.landing {
  @include flex-center;

  width: 100%;
  min-width: $min-width;
  max-width: $screen-xl-min;
  height: 100%;
  padding: 0 48px;
  flex-wrap: wrap;
  transition: $sidebar-transition;
  margin: 0 auto;

  @include gt-md {
    justify-content: space-between;
  }

  @include xs {
    padding: 0;
  }

  &--content {
    width: 440px;

    @include flex-justify-center;

    flex-direction: column;

    &-header {
      font-weight: 300;
      font-size: 32px;
      line-height: 48px;
      margin: 0;
      margin-bottom: 24px;
    }

    &-info {
      font-size: 14px;
      line-height: 24px;
      color: $color-grey-25;
      margin: 0;
      margin-bottom: 16px;
    }

    &-feature-section {
      margin-top: 50px;
      .main-text {
          font-size: 18px;
          display: block;
          margin-bottom: 30px;
      }
    }

    &-features-subsection1 {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      margin-bottom: 20px;
    }

    &-features-subsection2 {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      margin-bottom: 20px;
    }

    &-features-subsection3 {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      margin-bottom: 20px;
    }

    &-actions {      
      padding:22px 0 10px 0;
      display: flex;
      .register-button {
          margin-left: 16px;
          color: $color-primary-blue;
      }
      .login-button {
          background-color: $color-primary-blue;
          color: $white;
          a {
              color: $white;
          }
      }
    }
  }

  &--image {
    @include flex-center;

    min-width: 176px;
    max-width: 480px;

    img {
      width: 100%;
    }
  }
}
