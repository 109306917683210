@import '../../styles/mixins';
@import '../../styles/colors';
@import '../../styles/fonts';
@import '../../styles/constants';

$types: (
  text: 'text',
  outlined: 'outlined',
  default: '',
);
$colors: (
  primary: $color-brand-blue,
  secondary: $black,
  danger: $color-brand-red,
  success: $color-brand-green,
  dark: $black,
  transparent: transparent,
);

@mixin types-colors-modifiers {
  @each $typeName, $typeNameValue in $types {
    &__#{$typeName} {
      @each $colorName, $hex in $colors {
        &--#{$colorName} {
          @include reset-button-styles($background: $hex, $type: $typeNameValue);
        }
      }
    }
  }
}

$button-x-padding: 16px;
$button-y-padding: 4px;

.Deloitte__btn {
  $base: &;
  padding: {
    top: $button-y-padding;
    bottom: $button-y-padding;
    left: $button-x-padding;
    right: $button-x-padding;
  }

  &__withStartIcon {
    padding: {
      left: calc(#{$button-x-padding} / 2);
    }
  }

  &__withEndIcon {
    padding: {
      right: calc(#{$button-x-padding} / 2);
    }
  }

  font-family: $font-family-primary;
  font-weight: $font-weight-semi-bold;
  font-size: $font-size-normal;
  min-height: $button-height;
  line-height: normal;
  border-radius: 2px;

  @include flex-align-center;

  flex-wrap: nowrap;
  position: relative;

  & > .overlay,
  &__icon > .overlay {
    & > .sk-circle {
      margin: 0;
      width: calc(#{$button-height} - #{$button-height} / 4);
      height: calc(#{$button-height} - #{$button-height} / 4);
    }
  }

  & > .overlay {
    position: absolute;
  }

  &__icon,
  &__icon > .overlay {
    position: relative;

    @include flex-center;

    $icon-size: calc(#{$button-height} - #{$button-y-padding} * 2);

    margin-right: 8px;
    width: $icon-size;
    height: $icon-size;
    min-width: $icon-size;
  }

  & > span ~ &__icon {
    margin: 0 0 0 8px;
  }

  & > span {
    white-space: normal;
  }

  @include types-colors-modifiers;

  &__uppercase {
    text-transform: uppercase;
  }

  &__outlined--secondary {
    border: 1px solid $color-select-border;

    &:hover,
    &:disabled {
      border: 1px solid $color-select-border !important;
    }
  }
}
