@import '../../../styles/colors';
@import '../../../styles/fonts';

.pageContentHeader {
  margin-bottom: 24px;

  &.no-borders {
    border-bottom: none;
    padding-bottom: 0;
  }

  &__pageName,
  &__pageGroupName {
    margin: 0;
  }

  &__pageName {
    font-weight: $font-weight-semi-bold;
    line-height: 32px;
  }

  &__pageGroupName {
    margin-top: 4px;
    margin-bottom: -4px;
    font-weight: normal;
    line-height: $line-height-normal;
    font-size: $font-size-normal;
  }
}
