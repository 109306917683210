@import '../../styles/colors';
@import '../../styles/fonts';
@import '../../styles/constants';
@import '../../styles/mixins';

.select-control {
  & > .k-dropdown,
  & > .k-dropdowntree {
    width: 100%;

    & > .k-dropdown-wrap {
      height: 32px;
      align-items: center;
    }
  }

  .k-input {
    height: unset;
    display: unset;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 8px;
  }

  &--placeholder .k-input {
    color: $cool-gray-6;
  }

  .k-dropdown .k-dropdown-wrap {
    border: 1px solid $color-select-border;
    background: none;
    border-radius: 0;

    &:hover {
      background: none;
    }

    &:focus {
      border: 1px solid $color-select-border-hover;
      box-shadow: none;
    }
  }

  .k-dropdown.k-state-invalid .k-dropdown-wrap {
    border: 1px solid $error;
    background: $background-color-error;
  }

  .HeaderLanguageSelect.k-dropdown.k-widget {
    .k-dropdown-wrap {
      padding: 12px 10px;
      height: 100%;
      color: #212121 !important;
      font-weight: 600;
      font-style: normal !important;
      border: none;
      transition: background-color 0.5s ease;

      &:hover,
      &.k-state-focused {
        background-color: rgba(255, 255, 255, 0.16);
      }
    }
  }
}

.SingleSelectPopup {
  .k-list-scroller {
    @include custom-scrollbar;
  }

  &.HeaderLanguageSelectPopup.k-list-container {
    min-width: fit-content;
  }

  li.k-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 32px;
    display: list-item;
    &:hover,
    &.k-item-focused {
      &::after {
        content: url(../../assets/svg/RightTick.svg);
        position: absolute;
        right: 15px;
      }
    }

  }
  
}
.k-animation-container .k-list .k-item.k-state-selected {
  &::after {
    content: url(../../assets/svg/RightTick.svg);
    position: absolute;
    right: 15px;
  }
}
.label-form {
  color: $black;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
}

.form {
  input {
    border-radius: 0;
    font-size: 13px;
    height: $form-field-height;
    line-height: 24px;
  }
}

.error {
  color: $error;
  font-size: 10px;
  line-height: 16px;
}

.error-control {
  background: $background-color-error;
  border: 1px solid $error;

  .dropdown {
    background: $background-color-error;
    border: 1px solid $error !important;
  }
}

.dropdown-error {
  background: transparent;
  border: none;
}

.error-wrapper {
  min-height: 16px;
}

.form-group {
  margin-bottom: 0;
}
