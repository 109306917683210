@import '../../styles/colors';
@import '../../styles/mixins';

.modalLayout {
  .k-animation-container {
    z-index: 10002 !important;
  }

  &.k-dialog-wrapper {
    margin: 0 16px;
    width: calc(100% - 32px);
    height: 100%;
    display: grid;
    align-items: center;
  }

  .k-overlay {
    pointer-events: none;
  }

  & > .k-dialog {
    width: 480px;
    box-shadow: 0 8px 16px $color-rgba-black-10;
    border-radius: 2px;
    margin: 0 auto;
    max-height: 100%;
    display: block;
    overflow-y: auto;

    @include custom-scrollbar;

    & > .k-dialog-content {
      margin: 0 !important;
    }
  }

  .k-window-content:first-child {
    padding: 0;
  }

  .modalLayout-wrapper {
    padding: 24px 16px 24px 24px;
  }

  .modalLayoutHeader {
    @include flex-align-center;

    width: 100%;
    justify-content: space-between;
    color: $black;
    margin-bottom: 24px;

    &__title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin: 0;
      flex: 1;
    }

    svg {
      margin-right: 12px;
      cursor: pointer;
    }
  }

  .btn-group {
    display: flex;
    justify-content: flex-end;
    margin: 24px -8px 0;

    & > button {
      margin: 0 8px;
      min-height: 40px;
      padding: {
        top: 8px;
        bottom: 8px;
      }

      text-transform: uppercase;
    }

    .k-button {
      padding: 8px 16px;
      margin: 0 8px;
      background: $white;
      border: 1px solid $color-brand-blue;
      border-radius: 2px;

      &:hover {
        background: transparent;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  &__validation-message {
    font-size: 12px;
    margin-top: 15px;
    display: inline-block;
    line-height: 1.4;
    font-weight: 400;
    color: $dark-red;
  }

  &__description {
    font-size: 12px;
    color: $color-grey-45;
  }
}
