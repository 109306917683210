@import '../../styles/constants';
@import '../../styles/mixins';
@import '../../styles/fonts';
@import '../../styles/colors';

.mipTableWrapper {
  position: relative;

  .searchWrapper {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding: 16px;
    border: $table-border;
    border-bottom: none;

    .VendorInput__Wrapper {
      flex: 1 1;
      width: 400px;
    }
  }

  .mipTableActions-wrap {
    @include flex-center;

    flex-wrap: wrap;
    width: 100%;
    padding: 16px;
    border: $table-border;
    border-bottom: none;

    .mipTableActions {
      flex: 1;

      @include flex-align-center;

      justify-content: space-between;
      margin: -8px;
      flex-wrap: wrap;

      &-search {
        flex: 1;
        max-width: 400px;
        min-width: 200px;
        margin: 8px;

        input {
          text-overflow: ellipsis;
          padding-right: 85px;
        }
      }

      &-header {
        margin: 8px;
      }

      &-actions {
        @include flex-center;

        flex-wrap: wrap;

        &.justActions {
          margin-left: auto;
        }

        & > * {
          margin: 8px;
        }
      }
    }
  }

  .mipTable {
    .k-grid-norecords {
      background: $color-grey-90;
    }

    .Deloitte__Pagination__wrapper {
      border-top: $table-border;
    }

    .k-pager-wrap {
      background-color: $white;
    }

    .k-pager-sizes {
      order: 10;
      font-size: 0;

      .k-dropdown {
        font-size: 14px;
      }
    }

    .k-dropdown .k-dropdown-wrap,
    .k-dropdowntree .k-dropdown-wrap {
      background-color: $white;
    }

    .k-grid-header {
      padding-inline-end: 0;
      background: none;

      .k-icon {
        color: $color-primary-blue;
      }
    }

    .k-grid-header .k-i-sort-asc-sm,
    .k-grid-header .k-i-sort-desc-sm,
    .k-grid-header .k-sort-order {
      position: absolute;
      color: $color-brand-blue;
    }

    .k-header,
    th.k-header,
    .k-grid-header-wrap,
    .k-grouping-header,
    .k-grouping-header .k-group-indicator,
    &.k-grid td,
    .k-grid-footer,
    .k-grid-footer-wrap,
    .k-grid-content-locked,
    .k-grid-footer-locked,
    .k-grid-header-locked,
    .k-filter-row > td,
    .k-filter-row > th {
      border: none;
    }

    .k-pager-numbers .k-link {
      color: $body-text;
    }

    .k-pager-numbers .k-link:hover,
    .k-pager-numbers .k-link.k-state-hover {
      background-color: rgba(0, 125, 179, 0.1);
      color: $body-text;
    }

    .k-pager-numbers .k-link.k-state-selected {
      background: $color-brand-blue;
      color: $white;
    }

    .k-header {
      color: $color-grey-55;
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;

      &:last-child {
        min-width: 250px;
        display: block;
      }

      &.notSortable {
        background: none;
      }
    }

    .k-header:not(.notSortable) {
      padding-right: 25px;
    }

    .k-header.blueCell {
      background-color: $color-brand-blue;
      color: $white;

      .k-i-sort-asc-sm,
      .k-i-sort-desc-sm,
      .k-sort-order {
        color: $white;
      }
    }

    .k-grid-table col:last-child {
      width: 100%;
      min-width: 250px;
    }

    .k-grid-content {
      @include custom-scrollbar;

      overflow-y: hidden;
    }

    .k-header.k-sorted {
      .k-column-title {
        color: $color-primary-blue;
        margin-left: 20px;
      }

      .k-icon {
        &.k-i-sort-asc-sm {
          background: url('../../assets/svg/sort/sort-asc-active.svg') no-repeat 95%;
        }

        &.k-i-sort-desc-sm {
          background: url('../../assets/svg/sort/sort-desc-active.svg') no-repeat 95%;
        }

        &::before {
          display: none;
        }
      }

      &.blueCell {
        .k-column-title {
          color: $white;
          margin-left: 20px;
        }

        .k-icon {
          &.k-i-sort-asc-sm {
            background: url('../../assets/svg/sort/sort-asc-white.svg') no-repeat 95%;
          }

          &.k-i-sort-desc-sm {
            background: url('../../assets/svg/sort/sort-desc-white.svg') no-repeat 95%;
          }
        }
      }
    }
  }

  .overlay {
    height: unset;

    .sk-circle {
      margin: unset;
    }
  }

  &--noData {
    .overlay {
      top: 100px;
    }

    .k-grid-container::before {
      content: attr(data-noitemsmessage);
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: $font-size-normal;
      line-height: $line-height-normal;
      color: $color-grey-45;
      z-index: 1;
    }

    .k-grid-norecords {
      color: transparent !important;
      height: 100px;

      .placeholderForNoItemsText {
        display: none;
      }
    }
  }
}
