@import '../../../styles/colors';
@import '../../../styles/screens';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/constants';

.Deloitte__Pagination__wrapper {
  @include flex-center;

  width: 100%;
  padding: 12px 16px;
}

.Deloitte__Pagination {
  $base: &;

  flex: 1;

  @include flex-align-center;

  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 14px;
  margin: -4px;

  &__colWrapper {
    margin: 4px;
  }

  &__main {
    @include flex-align-center;

    margin: -4px -20px;
    justify-content: space-between;
    flex-wrap: wrap;

    & > div {
      margin: 4px 8px;
    }
  }

  &__manualPageField {
    @include flex-align-center;

    & > span {
      font-weight: 600;
      margin-right: 8px;
    }

    & > .VendorInput__Wrapper {
      width: 72px !important;
    }
  }

  &__block {
    @include flex-align-center;

    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.5px;

    #{$base}__pageItems {
      @include flex-align-center;

      flex-wrap: wrap;
      list-style: none;
      padding: 0;
      margin: -6px;

      #{$base}__arrow {
        @include flex-center;

        cursor: pointer;
        width: 24px;
        height: 24px;

        path {
          transition: $dropdown-transition;
        }

        &__forwardLeft {
          margin-right: 16px;
        }

        &__left {
          margin-right: 16px;
        }

        &__forwardRight {
          margin-left: 16px;
        }

        &__right {
          margin-left: 16px;
        }

        &:not(&--disabled):hover {
          path {
            fill: $color-brand-blue;
          }
        }

        &--disabled {
          pointer-events: none;
          cursor: default;

          path {
            fill: $canceled-grey;
          }
        }
      }
      #{$base}__pageItem {
        @include flex-center;

        cursor: pointer;
        min-width: 24px;
        height: 24px;
        border-radius: 2px;
        margin: 6px;
        padding: 0 4px;
        transition: $dropdown-transition;
        border: 1px solid transparent;
        color:#007CB0;

        &:hover {
          color:#000;
        }

        &__dots {
          cursor: default;
          border-color: transparent !important;
        }

        &--active {
          color: #000;
        }
      }
    }
  }
  .k-icon.k-i-arrow-chevron-down{
     color:#007CB0;
  }
  &__pageSize {
    @include flex-align-center;

    & > span {
      margin-left: 8px;
    }

    & > .select-control {
      width: 72px;
    }
  }
}
