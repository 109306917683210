@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap');
@import './styles/fonts';
@import './styles/colors';

:root {
  --color-primary: $color-brand-green;
}

body {
  margin: 0;
  font-family: $font-family-opensans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;

  * {
    box-sizing: border-box;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  overflow: hidden;
}

input[type='checkbox'] {
  cursor: pointer;

  & + label {
    cursor: pointer;
    padding: 0;
    position: relative;
  }
}

a {
  color: $color-brand-blue;

  & > svg {
    path {
      fill: $color-brand-blue;
    }
  }
}

.underline {
  text-decoration: underline;
}
