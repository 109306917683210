@import '../../../styles/mixins';
@import '../../../styles/colors';

button.tableDeleteAction,
button.tableExportAction:hover{
  background-color: #ffffff;
}
button.tableExportAction.Deloitte__btn__text--primary path{
  fill: $color-icon-green;
} 
button.tableAddNewAction {
  @include flex-center;

  white-space: nowrap;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
