@import '../../styles/colors';
@import '../../styles/mixins';

.unauthorized-container {
  height: 100%;
  flex-direction: column;

  @include flex-center;

  button {
    padding: 8px 16px;
    line-height: 22px;
    letter-spacing: 0.5px;
  }
}

.title {
  margin-bottom: 14px;
  font-size: 16px;
  font-weight: 600;
}

.description {
  margin-bottom: 25px;
  font-size: 14px;
  color: #000000;
}
