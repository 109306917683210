.editGrant {
  white-space: nowrap;
  color: #007db3;
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
