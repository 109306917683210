@import '../../../styles/colors';

.appFooter {
  padding: 16px 24px 24px;
  font-size: 12px;
  line-height: 16px;
  box-shadow: inset 0 1px 0 $color-rgba-black-4;
  margin-top: auto;

  & > p {
    margin: 0;
    margin-bottom: 8px;
    color: $color-grey-55;
  }

  & > p:last-child {
    margin-bottom: 0;
    color: $color-grey-25;
    font-weight: 600;
  }

  a,
  a:active,
  a:hover {
    text-decoration: none;
    color: $color-brand-blue;
  }

  a:hover {
    text-decoration: underline;
  }

  .footer__link {
    cursor: pointer;
  }
}
