@import '../../styles/colors';

.sidepanelAccordion{
.Collapsible {
  background-color: $color-primary-background;
  margin-top:5%;
  margin-left:6%;

  & + & {
    margin-top: 21px;
  }

  &__contentInner {
    padding: 0px;
    border: none;
    border-top: 0;
  }

  &__trigger {
    display: block;
    font-weight: 400;
    font-size: 14px;
    text-decoration: none;
    position: relative;
    border: none;
    padding: 0px;
    cursor: pointer;

    &::after {
      content: url(../../assets/svg/Caret-filled-Right.svg);
      position: absolute;
      right:15px !important;
      transition: transform 300ms;
      font-family: inherit;
      bottom:0px;
      left: unset;
    }
    &.is-open{
       font-weight:600;
       margin-left:5%;
    }

    &.is-open::after {
      content: url(../../assets/svg/Caret-filled-Left.svg);
      right:15px;
      transform: none;
      left: -8% !important;
      bottom: 0px;
    }

    &.is-disabled {
      opacity: 0.5;
      cursor: default;

      &::after {
        display: none;
      }
    }
  }
}
}