.Sidebar {
    position: fixed;
    width: 350px;
    min-height: 100vh;
    right: 0px;
    top: 48px;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.26);
    z-index: 1031;
    padding: 0!important;
}



.Header {
    top: 0;
    width: inherit;
}

.Footer{
    bottom: 0;
    position: absolute;
    width: inherit;
}