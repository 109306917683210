@import '../../../styles/colors';
@import '../../../styles/constants';

.NotificationIcon {
  cursor: pointer;
  min-width: 32px;
  display: flex;
  margin-right: 16px;
  .IconTooltip {
    display: inline-flex;
  
    &__iconWrapper {
      display: flex;
  
      & > svg > path {
        transition: fill 0.2s ease;
        fill: #000000;
      }
  
      &:hover > svg > path {
        fill: #000000;
      }
    }
  }

  .Badge {
    &.k-badge-edge.k-top-end {
      // transform: translate(calc(50% - 3px), calc(-50% + 3px));
    }
  }
}

.NotificationWrapper {
  $width: 400px;

  z-index: 1000;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $white;
  top: $header-height;
  right: -$width;
  height: $sidebar-height;
  width: $width;
  min-width: $width;
  max-width: $width;
  transition: $sidebar-transition;

  &.open {
    right: 0;
    box-shadow: 0 2px 5px $color-rgba-black-26;
  }

  &.loading {
    & > .NotificationList {
      pointer-events: none;
    }
  }

  & > .overlay {
    position: absolute;
    background: $color-rgba-black-4;
    top: 56px;
  }

  & > header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    padding-right: 22px;
    box-shadow: inset 0 -1px 0 $color-grey-75;

    & > svg {
      cursor: pointer;
    }
  }

  &__title {
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #212121;
  }
}

.NotificationCenter__toast {
  padding: 0;
  max-width: 400px;

  .Toastify__toast-body {
    padding: 0;
  }

  .NotificationCenter__item {
    box-shadow: none;
  }

  .Toastify__close-button {
    margin: {
      top: 18px;
      right: 18px;
    }
  }
}
