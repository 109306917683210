@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/constants';

.Badge {
  font-weight: 600;
  font-size: 10px;

  &.k-badge-solid.k-badge-primary {
    background: $color-brand-red;
    border: 1px solid $color-brand-red;
  }

  &.k-badge-rounded.k-badge-sm {
    border-radius: 8px;
  }

  &.k-badge-sm {
    min-width: 16px;
    min-height: 16px;
  }
}
