$font-family-primary: 'Open Sans', sans-serif;
$font-family-opensans: 'Open Sans', sans-serif;
$font-size-light: 12px;
$font-size-normal: 14px;
$font-size-semi-normal: 16px;
$font-size-lg: 22px;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-style-normal: normal;
$line-height-light: 18px;
$line-height-normal: 24px;
