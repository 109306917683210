@import '../../../styles/colors';

@mixin box-shadow($color: $color-grey-75) {
  box-shadow: inset 3px 0 0 $color, inset 0 -1px 0 $color-grey-75;
}

.NotificationCenter__item {
  background: $white;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  position: relative;

  &.clickable {
    cursor: pointer;

    &:active {
      background: #ededed9e;
    }
  }

  & > header {
    display: flex;
    align-items: center;
    width: 100%;

    & > svg,
    & > .IconTooltip {
      align-self: flex-start;
      margin-top: 4px;
    }

    & > svg {
      margin-left: 4px;
      min-width: 16px;
    }

    .IconTooltip {
      cursor: pointer;
      margin-left: auto;
      padding-left: 16px;
      display: none;

      &__iconWrapper {
        & > svg > path {
          fill: $black;
        }

        &:hover > svg > path {
          fill: $black;
        }
      }
    }
  }

  &:hover > header {
    .IconTooltip {
      display: inline-flex;
    }
  }

  @include box-shadow($color-primary-blue);

  &.Info {
    // @include box-shadow($color-primary-blue);
    & > header > svg > path {
      // fill: $color-icon-blue;
      fill: $color-icon-green;
    }
  }

  &.Warning {
    // @include box-shadow($color-brand-orange);
    & > header > svg > path {
      fill: $color-brand-orange;
    }
  }

  &.Error {
    // @include box-shadow($color-brand-red);
    & > header > svg > path {
      fill: $color-brand-red;
    }
  }

  &.Success {
    // @include box-shadow($color-icon-green);
    & > header > svg > path {
      fill: $color-icon-green;
    }
  }

  &__title {
    margin-left: 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $black;
  }

  &__description,
  &__date {
    margin-top: 16px;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $input-text;
  }

  &__date {
    color: #a3a3a3;
  }

  &.loading {
    pointer-events: none;

    & > .overlay {
      position: absolute;
    }

    & > *:not(.overlay) {
      opacity: 0.5;
    }
  }
}

.DeleteNotificationTooltip {
  & > .k-tooltip {
    font-weight: 600;
    padding: 6px 8px;
  }
}
