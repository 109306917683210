@import '../../styles/mixins';
@import '../../styles/colors';

.IconTooltip {
  @include flex-center;

  display: inline-flex;
  cursor: help;

  &__iconWrapper {
    display: flex;
    font-style:normal;

    & > svg > path {
      transition: fill 0.2s ease;
      fill: $color-select-border;
    }

    &:hover > svg > path {
      fill: $color-grey-25;
    }
  }
}

.IconTooltip__tooltip {
  max-width: 240px;

  & > .k-tooltip {
    border-color: $color-rgba-black-90;
    background-color: $color-rgba-black-90;
    border-radius: 2px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
  }
}
