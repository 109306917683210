@import '../../../styles/colors';
@import '../../../styles/constants';
@import '../../../styles/mixins';

.appHeader {
    @include flex-align-center;
    
    flex-wrap: nowrap;
    height: $header-height;
    width: 100%;
    background: $white;
    padding: 0px 48px 0px 48px;
    border: 1px solid #e0e0e0;

    &__divider {
        width: 1px;
        margin: 0 16px;
        background: $color-grey-25;
    }

    &__appName {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $white;
        margin-right: 16px;
    }

    &__actions {
        @include flex-align-center;
        
        height: 100%;
        flex-wrap: nowrap;
        margin-left: auto;
    }

    &__headerTitle {
        left: 150px;
        position: absolute;
        font-weight: 700;
        color: yellowgreen;
    }
    
}
