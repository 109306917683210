.sidebar {
    min-height: 100%;
    width: 0;
    position: absolute;
    z-index: 99999;
    background-color: #ffffff;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 12px;
    top: 0;
    bottom: 0; 
    box-shadow: 8px 8px 8px rgba(9, 30, 66, 0.15);
  }
  
  .sidebar p {
    padding: 3px 0px 0px 0px;
    text-decoration: none;
    font-size: 14px;
    color: #000000;
    display: block;
    transition: 0.3s;
  }
  .sidebar a {
    text-decoration: none;
    color: #000000;
    font-size: 14px;
    font-weight: 400;
}
.sidebar  a.homeFlag {
    display: block;
    margin-left: 6%;
    color: #000000;
    margin-top: 5%;
}
.sidebar  a.activeHomeFlag{
   color:#86BC25;
   display: block;
    margin-left: 6%;  
    margin-top: 5%;
}

.sidebar .manageAccountingInputs{
   color:#000000
}
.sidebar .activeManageAccountingColor{
  color:#86BC25;
}
.sidebar .generateAndViewExpensesColor{
  color:#000000
}
.sidebar .activeGenerateAndViewExpensesColor{
  color:#86BC25;
}
.sidebar .ActiveHomeManageAccountingColor{
  margin-top: 5%;
  margin-left: 6%;
  font-size: 14px;
  font-weight: 400;
  color:#000000
}
.sidebar .homeManageAccountingInputs{
   margin-top: 5%;
  margin-left: 6%;
  font-size: 14px;
  font-weight: 400;
}
.sidebar .homeGenerateAndViewExpenses{
  margin-top: 5%;
  margin-left: 6%;
  font-size: 14px;
  font-weight: 400;
}
.sidebar .span{
  font-size: 14px;
  font-weight: 400;
}
.sidebar .homeFiles{
  margin-top: 8%;
  margin-left: 6%;
  display:block;
  color:#000000;
}
.sidebar .activeHomeFiles{
  margin-top: 8%;
  margin-left: 6%;
  display:block;
  color:#86BC25;
}
.sidebar  a.files {
  display: block;
  margin-left: 6%;
  color: #000000;
  margin-top: 5%;
}
.sidebar  a.activeFiles {
  display: block;
  margin-left: 6%;
  color:#86BC25;
  margin-top: 5%;
}
  .sidebar a:hover {
    color: #000000;
  }

  .sidebar .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  
  .openbtn {
    font-size: 20px;
    cursor: pointer;
    background-color: transparent;
    color: #000000;
    padding: 5px 5px;
    border: none;
    position: absolute;
    bottom: 25px;
    left: 1px;
  }
  
 
  
  .sidebarToggle {
    transition: margin-left .5s;
    padding: 16px 16px 16px 22px;
    width: 48px;
    min-height: 100%;
    background: #ffffff;
    box-shadow: inset -1px 0px 0px #e0e0e0;
    position: relative;
  }
  
  .sidebarToggle .tab {
    margin-left: -18px;
    padding: 6px;
    display: inline-grid;
  }

  .sidebarToggle .tab:hover {
    background-color: transparent;
}
  
  .sidebarToggle .tab img {
    display: inline-block;
  }
 
 
  