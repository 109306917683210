@import '../../../styles/colors';
@import '../../../styles/fonts';

.SessionTimeoutModal {
  &__content {
    color: #000;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    font-weight: 400;
  }

  &__timer {
    display: block;
    font-size: 32px;
    line-height: 44px;
    font-weight: 400;
  }
}
