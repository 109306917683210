@import '../../styles/colors';
@import '../../styles/mixins';

.FormCheckbox {
  $base: &;

  position: relative;

  &--label-before {
    display: flex;
  }

  &__input {
    position: absolute;
    height: 16px;
    width: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    opacity: 0;

    &:not(:disabled) {
      cursor: pointer;
    }
  }

  &__label {
    @include flex-align-center;

    cursor: pointer;

    &--label-before {
      color: $black;
    }
  }

  &__label-text-before {
    margin-right: 16px;

    &--not-checked {
      color: $checkbox-border-color-default;
    }
  }

  &__label-text {
    color: $checkbox-label-color-default;

    &--checked {
      color: $color-brand-blue;
    }
  }

  &__square {
    @include flex-center;

    width: 16px;
    height: 16px;
    margin-right: 8px;
    background: $white;
    border: 1px solid $canceled-grey;
    border-radius: 2px;
    cursor: pointer;

    &:hover {
      border: 1px solid $color-brand-blue;
    }

    &--checked {
      background: $color-brand-blue;
      border: 1px solid $color-brand-blue;
    }

    &--no-label {
      margin-right: 0;
    }
  }

  &__icon {
    @include flex-center;

    visibility: hidden;

    &--checked {
      visibility: visible;
    }
  }

  &--disabled {
    & > input[type='checkbox'],
    .FormCheckbox__label,
    #{$base}__square {
      cursor: default;
    }
    #{$base}__square {
      border: 1px solid $canceled-grey;
      #{$base}__icon rect {
        fill: $canceled-grey;
      }
    }
  }
}
