@import './styles/colors';
@import './styles/fonts';

.Toastify {
  $toastify: &;
  white-space : pre-line !important;
  z-index: 20000;

  &__toast {
    $toast: &;

    &-container {
      width: auto;
      min-width: 400px;
      z-index: 20000;

      & > .Deloitte-toaster {
        color: $white;
        font-size: $font-size-normal;
        font-weight: normal;
        line-height: $line-height-normal;
        min-height: 40px;
        padding: 0;
        #{$toast}-body {
          margin: 0;
          padding: 16px 56px 16px 16px;
        }
        #{$toastify}__close-button {
          opacity: 1;
          position: absolute;
          right: 22px;
          top: 22px;
          width: 12px;
          height: 12px;

          &::before,
          &::after {
            position: absolute;
            left: 6px;
            top: -2px;
            content: ' ';
            height: 16px;
            width: 1.5px;
            background-color: $white;
          }

          &::before {
            transform: rotate(45deg);
          }

          &::after {
            transform: rotate(-45deg);
          }

          & > svg {
            display: none;
          }
        }
      }
      & > #{$toast} {
        &--error {
          // background-color: $toaster-error-background-color;
          background-color: $black;
        }

        &--warning {
          // background-color: $toaster-warning-background-color;
          background-color: $black;
        }

        &--success {
          // background-color: $toaster-success-background-color;
          background-color: $black;
        }
      }
    }
  }
}
