@import '../../../../styles/colors';

.modal-btn-display {
    padding-left: 235px;
    margin-top: 24px;
  }

  .border-line{
    border-top: 0.5px solid rgb(200, 200, 200);
    border-bottom: 0.5px solid rgb(200, 200, 200); 
    position:relative;
    margin: -12px;
    padding-left: -2px;
  }

  .links-container{
    margin: 10px;
  }
