@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/constants';
@import '../../../styles/mixins';

.select-control {
  $base: &;

  position: relative;

  &__clearable {
    &__clear__icon {
      position: absolute;
      right: 30px;
      bottom: 16px;
      width: max-content;
      height: max-content;
      transform: translateY(50%);
      cursor: pointer;
      display: none;
    }

    &:hover {
      .k-input {
        margin-right: 14px;
      }
      #{$base}__clearable__clear__icon {
        display: block;
      }
    }
  }

  & > .k-dropdown,
  & > .k-dropdowntree {
    width: 100%;

    & > .k-dropdown-wrap {
      height: 32px;
      align-items: center;
      box-shadow: none !important;
    }
  }

  .k-input {
    height: unset;
    display: unset;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 8px;
  }

  &--placeholder {
    .k-input {
      color: $cool-gray-6;
    }
  }

  .k-dropdown .k-dropdown-wrap {
    border: 1px solid $color-select-border;
    background: none;
    border-radius: 0;

    &:hover {
      background: none;
    }

    &:focus {
      border: 1px solid $color-select-border-hover;
      box-shadow: none;
    }
  }

  .k-dropdown.k-state-invalid .k-dropdown-wrap {
    border: 1px solid $error;
    background: $background-color-error;
  }
}

.SingleSelectPopup {
  .k-list-scroller {
    @include custom-scrollbar;
  }

  li.k-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 32px;
    display: list-item;
  }

  .k-list-filter {
    .k-textbox {
      outline: none !important;
      box-shadow: none !important;
      transition: $dropdown-transition;

      &:hover,
      &:focus,
      &:active {
        border: 1px solid $color-brand-blue;
      }
    }
  }
}

.label-form {
  color: $black;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
}

.form {
  input {
    border-radius: 0;
    font-size: 13px;
    height: $form-field-height;
    line-height: 24px;
  }
}

.error {
  color: $error;
  font-size: 10px;
  line-height: 16px;
}

.error-control {
  background: $background-color-error;
  border: 1px solid $error;

  .dropdown {
    background: $background-color-error;
    border: 1px solid $error !important;
  }
}

.dropdown-error {
  background: transparent;
  border: none;
}

.error-wrapper {
  min-height: 16px;
}

.form-group {
  margin-bottom: 0;
}
