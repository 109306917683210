@import '../../../styles/colors';
@import '../../../styles/mixins';

.confirm-modal {
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  a {
    color: $color-primary-blue;
    text-decoration: none;
    margin-top: 16px;
  }
}
