@import '../../styles/colors';

.k-calendar-yearview .k-calendar-td {
  padding: 10px 0px;
  font-size: 14px;
  border-bottom: 1px solid #ccc;
  font-weight:400;
}

.k-calendar-yearview tr:nth-child(3)>td:nth-child(1){border-bottom:none;}
.k-calendar-yearview tr:nth-child(3)>td:nth-child(2){border-bottom:none;}
.k-calendar-yearview tr:nth-child(3)>td:nth-child(3){border-bottom:none;}
.k-calendar-yearview tr:nth-child(3)>td:nth-child(4){border-bottom:none;}
.k-calendar-decadeview tr:nth-child(3)>td:nth-child(1){border-bottom:none;}
.k-calendar-decadeview tr:nth-child(3)>td:nth-child(2){border-bottom:none;}
.k-calendar-decadeview tr:nth-child(3)>td:nth-child(3){border-bottom:none;}
.k-calendar-decadeview tr:nth-child(3)>td:nth-child(4){border-bottom:none;}
.k-calendar-centuryview tr:nth-child(3)>td:nth-child(1){border-bottom:none;}
.k-calendar-centuryview tr:nth-child(3)>td:nth-child(2){border-bottom:none;}
.k-calendar-centuryview tr:nth-child(3)>td:nth-child(3){border-bottom:none;}
.k-calendar-centuryview tr:nth-child(3)>td:nth-child(4){border-bottom:none;}

.k-calendar-centuryview .k-calendar-td {
  border-bottom: 1px solid #ccc;
  padding: 10px 0px;
  font-size: 14px;
  font-weight: 400;
}
.k-calendar-decadeview .k-calendar-td {
  border-bottom: 1px solid #ccc;
  padding: 10px 0px;
  font-size: 14px;
  font-weight: 400;
}
.k-calendar-view .k-link {
  color: #000;
}

.k-calendar-header .k-calendar-title {
  font-weight: bold;
  font-size: 16px;
}

.k-calendar-header .k-today, .k-calendar-header .k-nav-today {
  font-weight: bold;
  color: #ffffff !important;
  font-size: 14px;
}
.k-button {
  font-size:14px;
  font-weight:400;
}
.k-calendar-header {
  background-color: #000000;
  color: #ffffff;
  padding: 12px 4px !important;
}
.datePicker {
  .k-datepicker {
    width: 100%;
    height: 32px;
    border-radius: 0;
  }

  input::placeholder {
    color: $cool-gray-6;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
  .k-datepicker .k-picker-wrap, .k-timepicker .k-picker-wrap, .k-datetimepicker .k-picker-wrap, .k-dateinput .k-dateinput-wrap {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ffffff;
}

  .k-picker-wrap.k-state-disabled.k-picker-wrap,
  .k-dateinput-wrap.k-state-disabled {
    background: $disabled-bg;
  }

  .k-state-focused.k-picker-wrap {
    box-shadow: none;
    border: 1px solid $color-brand-blue;
  }

  .k-picker-wrap {
    border: 1px solid $cool-gray-6;
    border-radius: 0;

    &:hover {
      box-shadow: none;
      border: 1px solid $cool-gray-6;
    }
  }

  .k-dropdown .k-dropdown-wrap {
    background: none;

    &:hover {
      background: none;
    }
  }

  .k-datepicker .k-select {
    background: none;

    &:hover {
      background: none;
    }
  }

  a > svg path {
    fill: $color-select-border;
  }

  .k-picker-wrap.k-state-invalid.k-picker-wrap {
    border: 1px solid $error;
    background-color: $background-color-error;

    .k-dateinput-wrap.k-state-invalid {
      background-color: $background-color-error;
    }
  }
}
