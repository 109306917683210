@import './styles/colors';
@import './styles/constants';
@import './styles/mixins';

.appWrapper {
  display: flex;
  flex: 1;
}

.appName {
  padding: 5px 0 5px 38px;
  background-color: #e9e9e9;
  font-size: 12px;
}

.appContent {
  height: $sidebar-height;
  overflow: auto;
  display: flex;
  flex-flow: column nowrap;
  background: $white;
  transition: $sidebar-transition;
  min-height: $sidebar-height;
  flex: 1;

  @include custom-scrollbar;

  &--content {
    width: 100%;
    flex: 1;
    padding: 16px 24px;
    position: relative;

    & > .overlay {
      position: absolute;
      bottom: unset;
      height: $sidebar-height;
    }
  }

  &--sidebarOpen {
    margin-left: $sidebar-width-open;
  }

  &--sidebarClosed {
    margin-left: $sidebar-width-closed;
  }
}

button:disabled {
  opacity: 0.4;
}

.serviceUnavailableMessage {
  height: 100vh;

  @include flex-center;

  font-size: 16px;
  font-weight: 600;
  color: $black;
}
